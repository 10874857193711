import { useMutation } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { HeaderSearcherInput } from '@fingo/lib/components/inputs';
import { useInvoiceLucilaCustomColumns, useInvoicePreColumns } from '@fingo/lib/constants';
import {
  CREATE_REPLACEMENTS,
  INVOICE_AMOUNT_DETAILS,
  INVOICE_TRANSFER_INFO,
} from '@fingo/lib/graphql';
import { useBooleanState, useFilteredQuery, useInvoices, useSnackBars } from '@fingo/lib/hooks';
import useUserHasPerm from '@fingo/lib/hooks/useUserHasPerm';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

const COLUMNS = ['folio', 'dateIssued', 'receiver_Name', 'receiver_Rut', 'amountWithIva'];

const ReplacementDialog = ({ selectedDocumentIds, cleanDocuments }) => {
  const [openDialog, toggleDialog, , closeDialog] = useBooleanState();
  const [globalFilter, setGlobalFilter] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
  const [useSurplusForReplacement, toggleUseSurplusForReplacement] = useBooleanState();

  const { invoices } = useInvoices(INVOICE_TRANSFER_INFO, {
    skip: !selectedDocumentIds?.length,
    variables: { id_In: selectedDocumentIds },
  });
  const { addAlert } = useSnackBars();
  const handleClose = useCallback(() => {
    setSelectedInvoiceIds([]);
    closeDialog();
  }, []);

  const [createReplacements, { loading: replacementsLoading }] = useMutation(CREATE_REPLACEMENTS, {
    variables: {
      replacedInvoiceIds: selectedInvoiceIds,
      replacingInvoiceIds: selectedDocumentIds,
      useSurplusForDifference: useSurplusForReplacement,
    },
    onCompleted: () => {
      setSelectedInvoiceIds([]);
      cleanDocuments();
      closeDialog();
      addAlert({ id: 'invoices-replaced', message: 'Facturas Reemplazadas' });
    },
    onError: () => {
      addAlert({ id: 'invoices-replaced', message: 'Ha ocurrido algún error' });
    },
    refetchQueries: [INVOICE_TRANSFER_INFO, INVOICE_AMOUNT_DETAILS],
  });
  const companyIds = invoices ? [...new Set(invoices.map((invoice) => invoice?.company.id))] : [];
  const { data, loading } = useFilteredQuery(INVOICE_AMOUNT_DETAILS, {
    variables: {
      first: 100,
      offset: 0,
      receiver_Rut: null,
      receiverId: null,
      companyId_In: companyIds,
      dateIssued_Gte: null,
      dateIssued_Lte: null,
      availableForReplacement: true,
      globalFilter,
    },
    skip: companyIds.length === 0,
    notifyOnNetworkStatusChange: true,
  });
  const rows = useMemo(() => data?.invoices?.edges?.map((edge) => edge.node) || [], [data]);
  const disabled = useMemo(
    () => !invoices?.length
    || !rows.length
    || companyIds.length > 1,
    [invoices, companyIds, rows],
  );
  const columns = useInvoicePreColumns(useInvoiceLucilaCustomColumns()).filter(
    (col) => COLUMNS.includes(col.field),
  );
  const userCanForceSurplus = useUserHasPerm('collection.add_replacement');
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        disabled={disabled}
        disableElevation
        onClick={toggleDialog}
        id="toggle-download-portfolio"
      >
        Reemplazar
      </Button>
      <FingoDialog
        open={openDialog}
        handleClose={handleClose}
        maxWidth="md"
        fullWidth
        title="Reemplazo de facturas"
      >
        <HeaderSearcherInput
          finder={{
            onFinderChange: (newValue) => {
              setPage(0);
              setGlobalFilter(newValue);
            },
            finderValue: globalFilter,
            searchPlaceHolder: 'Buscar...',
          }}
        />
        <Box mt={1}>
          <FingoDataGrid
            rows={rows}
            columns={columns}
            loadingWithSkeleton={loading}
            pagination
            paginationMode="server"
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(ids) => setSelectedInvoiceIds(ids)}
            selectionModel={selectedInvoiceIds}
            density="compact"
            maxHeight={500}
          />
        </Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            loading={replacementsLoading}
            disableElevation
            disabled={selectedInvoiceIds.length !== selectedDocumentIds?.length}
            id="toggle-download-portfolio"
            onClick={createReplacements}
          >
            Reemplazar facturas
          </LoadingButton>
          <FormControl sx={{
            display: userCanForceSurplus ? 'inherit' : 'none',
          }}
          >
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="top"
                control={<Switch />}
                label="Usar excedentes si montos no calzan"
                labelPlacement="right"
                checked={useSurplusForReplacement}
                onChange={toggleUseSurplusForReplacement}
              />
            </FormGroup>
          </FormControl>
        </Stack>
      </FingoDialog>
    </>
  );
};

ReplacementDialog.propTypes = {
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cleanDocuments: PropTypes.func.isRequired,
};

ReplacementDialog.defaultProps = {};

export default ReplacementDialog;
